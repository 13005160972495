import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import invariant from 'tiny-invariant'
import { api } from './lib/api-client'
import { authStore, getKehila } from './lib/auth'
import { idToCloudinaryUrl } from './lib/cloudinary'
import { kehilaLangLocaleMap } from './lib/i18next'

!import.meta.env.DEV &&
  Sentry.init({
    enabled: !import.meta.env.DEV,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    dsn: 'https://d640cfa6e9e6476b88ad5f4e6101d37b@o923451.ingest.sentry.io/4504785851121664',
    tracesSampleRate: 0.1,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_RUNTIME_ENVIRONMENT,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
  })

async function hydrate() {
  authStore.getState().setSwitchKehilaKey()
  // wait for the photos to load before hydrating
  const kehila = await getKehila({ ignoreHeaders: true })

  if (import.meta.env.VITE_BUILD_TIMESTAMP) {
    console.log('==Staging!==')
    console.log({ TIMESTAMP: import.meta.env.VITE_BUILD_TIMESTAMP })
    console.log({ By: import.meta.env.VITE_WHO_DEPLOYED })
  }

  invariant(kehila.kehilaLanguage, "Kehila's language is missing")
  // wait for the i18n to load before hydrating
  await i18next
    .use(initReactI18next)
    .use(Backend)
    .init({
      debug: false,
      fallbackLng: 'he',
      lng: kehilaLangLocaleMap[kehila.kehilaLanguage],
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      interpolation: {
        escapeValue: false,
      },
    })

  // @ts-expect-error wrong type for kehila key
  const { photos } = await api.kehilot.kehilotKeyPhotosRetrieve(kehila.key, {
    headers: { Authorization: null },
  })

  for (const photo of photos) {
    const img = new Image()
    img.src = idToCloudinaryUrl(photo) || photo
  }

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nextProvider i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </I18nextProvider>,
    )
  })
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
